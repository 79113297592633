import { FakeRequestHandlers } from '@libs/ts-fake-fetch-rest';

// Faker request handlers
// Auth
import { fakeLogin } from './auth/fakeLogin';

// Users
import {
  fakeGetUserData,
  fakeGetUsers,
  fakeGetUserFields,
  fakeAddUserField,
  fakeDeleteUserField,
  fakeEditUserField,
} from './users';

export const fakeApi: FakeRequestHandlers = {
  auth: {
    login: {
      post: fakeLogin,
    },
  },
  users: {
    me: {
      get: fakeGetUserData,
    },
    fields: {
      get: fakeGetUserFields,
      post: fakeAddUserField,
      delete: fakeDeleteUserField,
      patch: fakeEditUserField,
    },
    get: fakeGetUsers,
  },
};
