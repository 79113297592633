// Create REST class with all methods

// Utils
import { isEmpty } from '../utils/isEmpty';
import { RequestError } from '../RequestError';
import { dataToURL } from '../utils/dataToUrl';
import { jsonResponseHandler } from '../utils/jsonResponseHandler';
import { textResponseHandler } from '../utils/textResponseHandler';
import { blobResponseHandler } from '../utils/blobResponseHandler';
import { arrayBufferResponseHandler } from '../utils/arrayBufferResponseHandler';
import { formDataResponseHandler } from '../utils/formDataResponseHandler';

// Config
import { UNAUTHORIZED_CODE } from '../config';

// Types
export type ResponseTypes =
  | 'json'
  | 'text'
  | 'blob'
  | 'arrayBuffer'
  | 'formData';
export type ResponseHandlers = Record<
  ResponseTypes,
  (response: Response) => any
>;

export type TokensObject = {
  accessToken: string;
  refreshToken: string;
};

export type TokensObjectKeys = keyof TokensObject;

export interface RestConfig {
  baseUrl?: string;
  url?: string;
  isAuth?: boolean;
  storageTokenKey?: string;
  storageRefreshTokenKey?: string;
  refreshTokenParamKey?: string;
  options?: RequestInit;
  responseHandlers?: ResponseHandlers;

  refreshTokenEnabled?: boolean;
  refreshTokenEndpoint?: string;
  refreshTokenResponseTransform?: (response?: any) => TokensObject;
  onTokenRefreshSuccess?: (response: TokensObject, rest: Rest) => void;
  onTokenRefreshError?: (error: RequestError, rest: Rest) => void;
  transformBody?: (body: any) => any;
  transformObjectBody?: (body: any) => any;
  transformUrlBody?: (body: any) => any;
  transformJsonBody?: (body: any) => any;
  transformFormDataBody?: (body: any) => any;
}

export interface RestRequestConfig extends RestConfig {
  method?: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE';
  body?: any;
  fullUrl?: string;
  responseType?: ResponseTypes;
  accessToken?: string;
}

// Defaults
export const defResponseHandlers: ResponseHandlers = {
  json: jsonResponseHandler,
  text: textResponseHandler,
  blob: blobResponseHandler,
  arrayBuffer: arrayBufferResponseHandler,
  formData: formDataResponseHandler,
};

export class Rest implements RestConfig {
  public baseUrl?: string;
  public url?: string;
  public storageTokenKey?: string;
  public storageRefreshTokenKey?: string;
  public refreshTokenParamKey?: string;
  public accessToken?: string;
  public refreshToken?: string;
  public baseOptions?: RequestInit;
  public responseHandlers?: ResponseHandlers;
  public refreshTokenEnabled?: boolean;
  public refreshTokenEndpoint?: string;
  public refreshTokenResponseTransform?: (response: Response) => any;
  public onTokenRefreshSuccess?: (response: TokensObject, rest: Rest) => void;
  public onTokenRefreshError?: (error: RequestError, rest: Rest) => void;
  public transformBody: (body: any) => any;
  public transformObjectBody: (body: any) => any;
  public transformUrlBody: (body: any) => any;
  public transformJsonBody: (body: any) => any;
  public transformFormDataBody: (body: any) => any;

  constructor({
    baseUrl,
    url,
    storageTokenKey = 'accessToken',
    storageRefreshTokenKey = 'refreshToken',
    refreshTokenParamKey = 'refreshToken',
    options,
    responseHandlers = defResponseHandlers,

    refreshTokenEnabled = false,
    refreshTokenEndpoint = '/auth/refresh-token',
    refreshTokenResponseTransform = (r) => r,
    onTokenRefreshSuccess = () => {},
    onTokenRefreshError = () => {},
    transformBody = (b) => b,
    transformObjectBody = transformBody,
    transformUrlBody = transformObjectBody,
    transformJsonBody = transformObjectBody,
    transformFormDataBody = transformBody,
  }: RestConfig) {
    this.storageTokenKey = storageTokenKey;
    this.storageRefreshTokenKey = storageRefreshTokenKey;
    this.baseUrl = baseUrl;
    this.baseOptions = options || {};
    this.url = url;
    this.responseHandlers = responseHandlers;
    this.refreshTokenEnabled = refreshTokenEnabled;
    this.refreshTokenEndpoint = refreshTokenEndpoint;
    this.refreshTokenParamKey = refreshTokenParamKey;
    this.refreshTokenResponseTransform = refreshTokenResponseTransform;
    this.onTokenRefreshSuccess = onTokenRefreshSuccess;
    this.onTokenRefreshError = onTokenRefreshError;
    this.transformBody = transformBody;
    this.transformObjectBody = transformObjectBody;
    this.transformUrlBody = transformUrlBody;
    this.transformJsonBody = transformJsonBody;
    this.transformFormDataBody = transformFormDataBody;

    this.getTokens();
  }

  setTokens(obj: Partial<Record<TokensObjectKeys, string>>) {
    const { storageTokenKey, storageRefreshTokenKey } = this;
    const { accessToken, refreshToken } = obj;

    if (accessToken) {
      try {
        localStorage.setItem(storageTokenKey as string, accessToken);
      } catch (e) {
        console.log('unable to set cookie', storageTokenKey);
      }

      this.accessToken = accessToken;
    }

    if (refreshToken) {
      try {
        localStorage.setItem(storageRefreshTokenKey as string, refreshToken);
      } catch (e) {
        console.log('unable to set cookie', storageRefreshTokenKey);
      }

      this.refreshToken = refreshToken;
    }

    return obj;
  }

  removeTokens() {
    const { storageTokenKey, storageRefreshTokenKey } = this;

    try {
      localStorage.removeItem(storageTokenKey as string);
    } catch (e) {
      console.log('unable to delete cookie', storageTokenKey);
    }

    try {
      localStorage.removeItem(storageRefreshTokenKey as string);
    } catch (e) {
      console.log('unable to delete cookie', storageRefreshTokenKey);
    }

    this.accessToken = undefined;
    this.refreshToken = undefined;

    return null;
  }

  getTokens() {
    const { storageTokenKey, storageRefreshTokenKey } = this;
    console.log('get local storage tokens', this);
    let accessToken = this.accessToken;
    try {
      accessToken =
        localStorage.getItem(storageTokenKey as string) || this.accessToken;
      this.accessToken = accessToken;
    } catch (e) {
      console.log('unable to get key from localStorage: ', storageTokenKey);
    }

    let refreshToken = this.refreshToken;
    try {
      refreshToken =
        localStorage.getItem(storageRefreshTokenKey as string) ||
        this.refreshToken;
      this.refreshToken = refreshToken;
    } catch (e) {
      console.log(
        'unable to get key from localStorage: ',
        storageRefreshTokenKey
      );
    }

    return {
      accessToken,
      refreshToken,
    };
  }

  async request({
    url,
    fullUrl,
    isAuth,
    body,
    method = 'GET',
    responseType = 'json',
    options = {},
    responseHandlers,
    refreshTokenEnabled = this.refreshTokenEnabled,
    accessToken: paramToken,
  }: RestRequestConfig) {
    const {
      accessToken: thisToken,
      baseUrl = '',
      baseOptions = {},
      executeTokenRefresh,
    } = this;
    const token = paramToken || thisToken;

    const _responseHandlers = {
      ...(this.responseHandlers || {}),
      ...(responseHandlers || {}),
    };

    let _fullUrl = fullUrl || `${baseUrl}${url}`;

    const baseHeaders = baseOptions?.headers;
    const optionsHeaders = options?.headers;
    const headers = new Headers({
      ...(baseHeaders || {}),
      ...(optionsHeaders || {}),
    });
    const _options = { ...baseOptions, ...options, method, headers };

    if (isAuth) {
      if (!headers.has('Authorization')) {
        if (token) {
          headers.append('Authorization', `Bearer ${token}`);
        } else {
          const { accessToken: storageToken } = this.getTokens();

          if (storageToken) {
            headers.append('Authorization', `Bearer ${storageToken}`);
          } else {
            throw new RequestError({
              message: 'Unauthorized',
              status: UNAUTHORIZED_CODE,
            });
          }
        }
      }
    }

    if (!_options.body) {
      if (method === 'GET') {
        // Convert body to query string
        if (!isEmpty(body)) {
          _fullUrl += `?${dataToURL(this.transformUrlBody(body))}`;
        }
      } else {
        if (body) {
          if (body instanceof FormData) {
            _options.body = this.transformFormDataBody(body);
          } else {
            headers.append('Content-Type', 'application/json');
            _options.body = JSON.stringify(this.transformJsonBody(body));
          }
        }
      }
    }

    const executeRequest = async () => {
      const response = await fetch(_fullUrl, _options);

      const handler = _responseHandlers?.[responseType];
      if (handler) {
        const processedResponse = await handler(response);

        if (!response.ok) {
          throw new RequestError(processedResponse);
        }

        return processedResponse;
      } else {
        if (!response.ok) {
          throw new RequestError(response);
        }

        throw new RequestError({
          message: `Handler for ${responseType} not found`,
        });
      }
    };

    try {
      return await executeRequest();
    } catch (error: any) {
      if (refreshTokenEnabled && error?.status === UNAUTHORIZED_CODE) {
        const tokens = await executeTokenRefresh.call(this);

        if (tokens) {
          headers.set('Authorization', `Bearer ${tokens.accessToken}`);
          return await executeRequest();
        }
      }

      if (error instanceof RequestError) {
        throw error;
      } else {
        throw new RequestError(error);
      }
    }
  }

  async get(url = '', isAuth: boolean, body: any, config?: RestRequestConfig) {
    return this.request({ url, isAuth, body, method: 'GET', ...config });
  }

  async post(url = '', isAuth: boolean, body: any, config?: RestRequestConfig) {
    return this.request({ url, isAuth, body, method: 'POST', ...config });
  }

  async patch(
    url = '',
    isAuth: boolean,
    body: any,
    config?: RestRequestConfig
  ) {
    return this.request({ url, isAuth, body, method: 'PATCH', ...config });
  }

  async put(url = '', isAuth: boolean, body: any, config?: RestRequestConfig) {
    return this.request({ url, isAuth, body, method: 'PUT', ...config });
  }

  async delete(
    url = '',
    isAuth: boolean,
    body: any,
    config?: RestRequestConfig
  ) {
    return this.request({ url, isAuth, body, method: 'DELETE', ...config });
  }

  async executeTokenRefresh() {
    const {
      refreshToken,
      refreshTokenEndpoint,
      refreshTokenParamKey,
      refreshTokenResponseTransform = (r) => r,
      onTokenRefreshSuccess = () => {},
      onTokenRefreshError = () => {},
    } = this;

    if (!refreshToken)
      return await onTokenRefreshError(
        new RequestError('No refresh token in storage'),
        this
      );

    try {
      const response = await this.get(
        refreshTokenEndpoint,
        true,
        { [refreshTokenParamKey as string]: refreshToken },
        { refreshTokenEnabled: false }
      );

      const {
        accessToken: newToken,
        refresh_token,
        refreshToken: newRefreshToken = refresh_token,
      } = refreshTokenResponseTransform(response);

      const newTokens: TokensObject = {
        accessToken: newToken,
        refreshToken: newRefreshToken,
      };
      this.setTokens(newTokens);
      await onTokenRefreshSuccess(newTokens, this);

      return newTokens;
    } catch (error) {
      await onTokenRefreshError(error, this);
      throw error;
    }
  }
}
