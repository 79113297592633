// Rest client
export * from '@libs/next-fetch-rest';
import { Rest, RequestError } from '@libs/next-fetch-rest';

// React query
export * from '@tanstack/react-query';
import { QueryCache, QueryClient } from '@tanstack/react-query';

// Fake rest client
export * from '@libs/next-fake-fetch-rest';
import { FakeRestConfig, FakeRest } from '@libs/next-fake-fetch-rest';

// Fake api routes
import { fakeApi } from './fake-api';

// Helpers
import { transformObjectBody } from './helpers/transformObjectBody';

// Re export
export { RequestError };

// Re export types
export type { FakeRestConfig };

// Create query client for context
export const queryCache = new QueryCache();

export const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {},
  },
});

// REST Http Client configuration
export const apiPrefix = '/api';
export let baseUrl =
  process.env.NEXT_PUBLIC_API_URL || `http://localhost:5000${apiPrefix}`;

if (global?.window) {
  const { protocol, hostname, port } = window.location;

  baseUrl =
    process.env.NEXT_PUBLIC_API_URL || hostname === 'localhost'
      ? baseUrl
      : `${protocol}//${hostname}${port ? `:5000` : ''}${apiPrefix}`;
}

const fakeRest = new FakeRest({
  baseUrl,
  fakeRequestHandlers: fakeApi,
  log: true,
  transformObjectBody,
} as FakeRestConfig);

export const originalRest = new Rest({
  baseUrl,
  refreshTokenEnabled: true,
  refreshTokenEndpoint: '/manager-auth/refresh-token',
  transformObjectBody,
  onTokenRefreshError: (error, rest) => {
    console.log('Refresh token error', error);
    rest.removeTokens();

    if (global?.window) {
      global.window.location.href = '/auth/sign-in';
    }

    queryClient.clear();
  },
});

export const rest: typeof originalRest & typeof fakeRest = originalRest;

export default originalRest;
