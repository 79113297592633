import { faker } from '@faker-js/faker';

// Types
import type { GetManagerDataResponse } from 'modules/auth/api/apiGetUserData';

export interface FakeUserGenerateOptions {}

export const fakeUserGenerate = (
  options: FakeUserGenerateOptions = {}
): GetManagerDataResponse => ({
  id: faker.number.int({ min: 1 }),
  firstName: faker.person.firstName(),
  lastName: faker.person.lastName(),
  email: faker.internet.email(),
  phone: faker.phone.number(),
  location: faker.location.city(),
  avatar: faker.internet.avatar(),
  role: faker.helpers.arrayElement(['Manager', 'Designer', 'Developer']),
});
