// Original types
import { Rest as OriginalRest, RequestError } from '@libs/ts-fetch-rest';

// Types
import type {
  RestConfig,
  RestRequestConfig,
  ResponseHandlers,
  TokensObject,
} from '@libs/ts-fetch-rest';

// Utils
import { deepSearchKey } from '@tools/ts-deep-search-key';
import { faker, Faker } from '@faker-js/faker';

// Re-export types
export type { RestConfig, ResponseHandlers, TokensObject };

// Types
export interface FakeRestConfig extends RestConfig {
  delay?: number;
  fakeRequestHandlers?: FakeRequestHandlers;
  log?: boolean;
}

export interface FakeRestRequestConfig
  extends FakeRestConfig,
    RestRequestConfig {}

export interface FakeRestRequestHandlerConfig extends FakeRestRequestConfig {
  urlKeys: string[];
}

export type FakeRequestHandler = (
  config: FakeRestRequestHandlerConfig,
  faker: Faker
) => Promise<any>;
export type FakeRequestHandlers = Record<string, FakeRequestHandler | unknown>;

// Defaults
export const defFakeRestOptions: FakeRestConfig = {
  storageTokenKey: 'fakeToken',
  storageRefreshTokenKey: 'fakeRefreshToken',
};
export class FakeRest extends OriginalRest implements FakeRestConfig {
  public fakeRequestHandlers?: FakeRequestHandlers;
  public log?: boolean;

  constructor(options: FakeRestConfig) {
    super(options);
    this.fakeRequestHandlers = options.fakeRequestHandlers || {};
    this.log = options.log;
  }

  override async request(config: FakeRestRequestConfig) {
    // Retrieve global config
    const {
      fakeRequestHandlers: requestHandlers,
      log: globalLog,
      baseOptions = {},
      accessToken: thisToken,
    } = this;

    // Retrieve params config
    const {
      url = '',
      delay = 1000,
      log = globalLog,
      method = 'GET',
      options = {},
      isAuth = false,
      accessToken: paramToken,
    } = config;
    const token = paramToken || thisToken;

    // Make path to fake request handler
    const urlKeys = url.split('/').filter((key) => key);
    urlKeys.push(method.toLowerCase());

    const baseHeaders = baseOptions?.headers;
    const optionsHeaders = options?.headers;
    const headers = new Headers({
      ...(baseHeaders || {}),
      ...(optionsHeaders || {}),
    });
    const _options = { ...baseOptions, ...options, method, headers };
    const _config = { ...config, options: _options, urlKeys: [...urlKeys] };

    // Get fake request handler or return empty function
    const fakeHandler: FakeRequestHandler =
      deepSearchKey(requestHandlers as any, urlKeys) ||
      ((): Partial<RequestError> => ({
        error: true,
        message: `No fake request handler found for ${method} ${url}`,
      }));

    if (isAuth) {
      if (!headers.has('Authorization')) {
        if (token) {
          headers.append('Authorization', `Bearer ${token}`);
        } else {
          const { accessToken: storageToken } = this.getTokens();

          if (storageToken) {
            headers.append('Authorization', `Bearer ${storageToken}`);
          } else {
            throw new RequestError({
              message: 'Unauthorized',
              status: 401,
            });
          }
        }
      }
    }

    const executeRequest = async () => {
      await new Promise((resolve) => setTimeout(resolve, delay || 0));

      try {
        const response = await fakeHandler(
          _config as FakeRestRequestHandlerConfig,
          faker
        );

        if (log) {
          console.log(`\n`);
          console.log('Fake request', {
            ..._config,
            urlKeys,
            globalConfig: this,
            response,
          });
          console.log('\n');
        }

        if (response?.error) {
          throw response;
        }

        return response;
      } catch (error) {
        throw new RequestError(error);
      }
    };

    return await executeRequest();
  }
}
