// Libs
import { Rest, RestConfig, TokensObjectKeys } from '@libs/ts-fetch-rest';
import { setCookie, getCookie, deleteCookie } from 'cookies-next';

// Types
export type GetTokensOptions = { cookies: any };
export const nextRestMixin = (RestClass: typeof Rest) => {
  return class extends RestClass {
    constructor(options: RestConfig) {
      super(options);
    }
    override setTokens(obj: Partial<Record<TokensObjectKeys, string>>) {
      const { storageTokenKey, storageRefreshTokenKey } = this;
      const { accessToken, refreshToken } = obj;

      if (accessToken) {
        try {
          deleteCookie(storageTokenKey as string);
          setCookie(storageTokenKey as string, accessToken);
        } catch (e) {
          console.log('unable to set cookie', storageTokenKey);
        }

        this.accessToken = accessToken;
      }

      if (refreshToken) {
        try {
          deleteCookie(storageRefreshTokenKey as string);
          setCookie(storageRefreshTokenKey as string, refreshToken);
        } catch (e) {
          console.log('unable to set cookie', storageRefreshTokenKey);
        }

        this.refreshToken = refreshToken;
      }

      return obj;
    }

    override removeTokens() {
      const { storageTokenKey, storageRefreshTokenKey } = this;

      try {
        deleteCookie(storageTokenKey as string);
      } catch (e) {
        console.log('unable to delete cookie', storageTokenKey);
      }

      try {
        deleteCookie(storageRefreshTokenKey as string);
      } catch (e) {
        console.log('unable to delete cookie', storageRefreshTokenKey);
      }

      this.accessToken = undefined;
      this.refreshToken = undefined;

      return null;
    }

    override getTokens() {
      const { storageTokenKey, storageRefreshTokenKey } = this;

      let accessToken = this.accessToken;
      try {
        accessToken = getCookie(storageTokenKey as string) || this.accessToken;
        this.accessToken = accessToken;
      } catch (e) {
        console.log('unable to get cookie', storageTokenKey);
      }

      let refreshToken = this.refreshToken;
      try {
        refreshToken =
          getCookie(storageRefreshTokenKey as string) || this.refreshToken;
        this.refreshToken = refreshToken;
      } catch (e) {
        console.log('unable to get cookie', storageRefreshTokenKey);
      }

      return {
        accessToken,
        refreshToken,
      };
    }

    getServerTokens({ cookies }: GetTokensOptions) {
      const { storageTokenKey, storageRefreshTokenKey } = this;

      let serverCookies: any;
      try {
        serverCookies = cookies();
      } catch (e) {
        console.log('unable to get server cookies');
      }

      let accessToken;
      try {
        accessToken = serverCookies.get(storageTokenKey)?.value || '';
        this.accessToken = accessToken;
      } catch (e) {
        console.log('unable to get server cookie', storageTokenKey);
      }

      let refreshToken;
      try {
        refreshToken = serverCookies.get(storageRefreshTokenKey)?.value || '';
        this.refreshToken = refreshToken;
      } catch (e) {
        console.log('unable to get server cookie', storageRefreshTokenKey);
      }

      return {
        accessToken,
        refreshToken,
      };
    }
  };
};
