import {
  FakeRest as ParentFakeRest,
  FakeRestConfig,
} from '@libs/ts-fake-fetch-rest';
import { nextRestMixin } from '@libs/next-fetch-rest-mixin';

// Re export types
export type { FakeRestConfig };

export const FakeRest = nextRestMixin(ParentFakeRest);
