// State
import { fakeUserFieldsState } from './fakeGetUserFields';

// Types
import { FakeRestRequestHandlerConfig } from '@libs/ts-fake-fetch-rest';
import type { EditUserFieldResponse } from 'modules/users/api/apiEditUserField';

export const fakeEditUserField = async (
  config: FakeRestRequestHandlerConfig,
  faker
): Promise<EditUserFieldResponse> => {
  const { urlKeys, body = {} } = config;

  const fieldName = urlKeys.pop();

  const fieldIndex = fakeUserFieldsState.customFields.findIndex(
    (field) => field.name === fieldName
  );
  const updatedField = fakeUserFieldsState.customFields[fieldIndex];

  if (updatedField) {
    // Update the field with the new values from the request body
    Object.assign(updatedField, body);
  }

  return updatedField;
};
