import {
  FakeRequestHandler,
  FakeRestRequestConfig,
} from '@libs/ts-fake-fetch-rest';

// Utils
import { fakeUserGenerate } from '../utils/fakeUserGenerate';

export const fakeGetUserData: FakeRequestHandler = async (
  config: FakeRestRequestConfig,
  faker,
) => {
  return fakeUserGenerate();
};
