// Delete empty fields
const emptyFieldsToDelete = ['sortBy', 'sortOrder', 'limit', 'search'];

// Replace field values
const fieldsToReplace = [['limit', 'take']];

// Make field value uppercase
const fieldsToUpperCase = ['sortOrder'];

export const transformObjectBody = (body: any) => {
  // Delete empty fields
  emptyFieldsToDelete.forEach((key) => {
    if (!body[key]) {
      delete body[key];
    }
  });

  // Replace field values
  fieldsToReplace.forEach(([key, replaceKey]) => {
    if (key in body) {
      body[replaceKey] = body[key];
      delete body[key];
    }
  });

  // Make field value uppercase
  fieldsToUpperCase.forEach((key) => {
    if (typeof body[key] === 'string') {
      body[key] = body[key].toUpperCase();
    }
  });

  return body;
};
