// State
import { fakeUserFieldsState } from './fakeGetUserFields';

// Types
import { FakeRestRequestHandlerConfig } from '@libs/ts-fake-fetch-rest';
import type { DeleteUserFieldResponse } from 'modules/users/api/apiDeleteUserField';

export const fakeDeleteUserField = async (
  config: FakeRestRequestHandlerConfig,
  faker
): Promise<DeleteUserFieldResponse> => {
  const { urlKeys } = config;

  const fieldName = urlKeys.pop();

  let removedField = null;

  const newFields = fakeUserFieldsState.customFields.filter((field) => {
    if (field.name === fieldName) {
      removedField = field;
      return false;
    }
  });

  fakeUserFieldsState.customFields = newFields;

  return removedField;
};
