// State
import { fakeUserFieldsState } from './fakeGetUserFields';

// Types
import type { AddUserFieldResponse } from 'modules/users/api/apiAddUserField';

export const fakeAddUserField = async (
  config,
  faker
): Promise<AddUserFieldResponse> => {
  const { body } = config;

  fakeUserFieldsState.customFields.push(body);

  return body;
};
