import { FakeRequestHandler } from '@libs/ts-fake-fetch-rest';

// Types
import type { LoginResponse } from 'modules/auth/api/apiLogin';

export const fakeLogin: FakeRequestHandler = async (
  config,
  faker
): Promise<LoginResponse> => {
  return {
    accessToken: `fake-${faker.string.alphanumeric(20)}`,
    refreshToken: `fake-${faker.string.alphanumeric(20)}`,
  };
};
