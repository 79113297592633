import { FakeRequestHandler, faker } from '@libs/ts-fake-fetch-rest';

// Utils
import { fakeUserGenerate } from '../utils/fakeUserGenerate';

// Types
import { GetUsersResponse } from 'modules/users/api/apiGetUsers';

export const maxUsersCount = faker.faker.number.int({ min: 10, max: 1000 });

export const fakeGetUsers: FakeRequestHandler = async (
  config,
  faker
): Promise<GetUsersResponse> => {
  const { body = {} } = config as any;
  const { limit = 10 } = body;

  const users = Array.from({ length: limit }, fakeUserGenerate);

  return {
    data: users,
    meta: {
      total: maxUsersCount,
    },
  };
};
