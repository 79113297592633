// Utils
import {
  FakeRequestHandler,
  FakeRestRequestConfig,
} from '@libs/ts-fake-fetch-rest';

// Types
import type { UserField } from 'modules/users/api/types';

export const fakeUserFieldsState = {
  fields: [
    {
      label: 'ID',
      name: 'id',
      type: 'number',
      hiddenRead: true,
      hiddenCreate: true,
      readOnly: true,
    },
    {
      label: 'First Name',
      name: 'firstName',
      type: 'string',
    },
    {
      label: 'Last Name',
      name: 'lastName',
      type: 'string',
      hiddenRead: true,
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
    },
    {
      label: 'Location',
      name: 'location',
      type: 'string',
    },
    {
      label: 'Phone',
      name: 'phone',
      type: 'phone',
    },
  ] as UserField[],
  customFields: [],
};

export const fakeGetUserFields: FakeRequestHandler = async (
  config: FakeRestRequestConfig,
  faker
) => {
  return [...fakeUserFieldsState.fields, ...fakeUserFieldsState.customFields];
};
